<template>
  <div class="check-inpage">
    <div class="check-l">
      <div class="check-l__top">
        <el-form label-position="top">
          <el-form-item label="日期:">
            <el-date-picker
                v-model="time"
                type="date"
                placeholder="选择日期"
                @change="timeChange"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="房间筛选:">
            <el-select v-model="roomType" placeholder="房间类型" @change="roomTypeChange">
              <el-option v-for="item in roomTypeList" :label="item.name" :value="item.pid" :key="item.pid"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="check-l__bottom">
        <div class="check-l__btn">
          <el-button class="btn" type="primary" @click="setState('1')">已入住</el-button>
        </div>
        <div class="check-l__btn">
          <el-button class="btn" type="info" @click="setState('0')">空净房</el-button>
        </div>
      </div>
    </div>
    <div class="check-r">
      <section v-for="item in roomList" :key="item.pid">
        <div class="check-r__title">
          房间类型({{ item.name }}）
        </div>
        <div class="check-r__list">
          <div class="check-r__item" :class="{'item__in':room.state=='1'}" v-for="room in item.roomStatusVOList"
               @click="handleInPopup(room,item)"
               :key="room.pid">
            <div class="item__state " :class="room.state=='1'?'state__in':'state__wait'">
              {{ room.state == '1' ? '已入住' : '待入住' }}
            </div>
            <div class="item_no">房间号{{ room.name }}</div>
            <div class="item_name" v-if="room.state=='1'">{{ room.userName }}</div>
            <div class="item_date" v-if="room.state=='1'">离店日期:{{ room.leaveDate }}</div>
          </div>
        </div>
      </section>
    </div>
    </div>
</template>

<script>
import { roomStatus, RoomType } from "../../api/lookRoomState";

export default {
  name: "index",
  data() {
    return {
      time: '',
      roomType: '',
      roomTypeList: [],
      roomList: [],
      state: ''
    }
  },
  mounted() {
    this.GetRoomType()
    this.getList()
  },
  methods: {
    // 获取房间列表
    async getList() {
      const parms = {
        roomDate: this.time,
        roomTypeId: this.roomType,
        state: this.state
      }
      const res = await roomStatus(parms)
      console.log(res)
      if (res.ResultCode === 0) {
        this.roomList = res.Data
      }
    },
    // 获取房型列表
    async GetRoomType() {
      const res = await RoomType()
      console.log(res)
      if (res.ResultCode === 0) {
        this.roomTypeList = res.Data
      }
    },
    timeChange(e) {
      console.log(e)
      this.getList()
    },
    roomTypeChange(e) {
      console.log(e)
      this.getList()
    },
    setState(e) {
      this.state = e
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.check-inpage {
  //width: 1133px;
  //height: 650px;
  background: #FFFFFF;
  //padding: 50px 0;
  box-sizing: border-box;
  //border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;

  .check-l {
    width: 312px;
    height: 650px;
    padding: 50px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__top {
    }

    &__bottom {

    }

    &__btn {
      .btn {
        width: 80px;
        margin-bottom: 32px;
        height: 38px;
      }
    }
  }

  .check-r {
    padding: 50px 0;
    flex: 1.2;
    overflow-y: scroll;
    &__title {
      width: 786px;
      border-bottom: 1px solid #f6f6f6;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 38px;
      height: 39px;
      position: relative;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        content: " ";
        width: 56px;
        height: 3px;
        background: #348DF0;
      }
    }

    &__list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &__item {
      margin: 16px 15px 12px 0;
      width: 184px;
      height: 100px;
      background: #F6F6F6;
      border-radius: 4px;
      overflow: hidden;
      padding: 7px 7px 0 14px;
      box-sizing: border-box;
      border: 2px solid #f6f6f6;
      position: relative;

      .item_no {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #8A8A8A;
        line-height: 24px;
      }

      .item_name {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #515B78;
        line-height: 24px;
        margin-bottom: 6px;
        margin-top: 6px;
      }

      .item_date {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #515B78;
        line-height: 24px;
      }

      .item__state {
        position: absolute;
        right: 7px;
        top: 10px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;

      }

      .state__in {
        color: #FF7E00;
      }

      .state__wait {
        color: #348DF0;
      }
    }

    .item__in {
      border-color: #348DF0;
      background: rgba(52, 141, 240, 0.15);

      .item_name, .item_date {
        color: #333333;
      }
    }
  }
}
</style>
