import request from "../request/request";
const qs = require('qs')
export function roomStatus(data) {
    let params = qs.stringify(data)
    return request({
        url: '/yylb/calendar_roomMgr/roomStatus?' + params,
        method: 'GET'
    })
}

export function RoomType() {
    return request({
        url: '/yylb/calendar_roomMgr/listAll',
        method: 'get'
    })
}
